:root {
  --socialTop: 18px;
}
.socialmedia {
  background-color: #0d0d0d;
  padding: 18px;
  position: fixed;
  top: var(--socialTop);
  right: 15px;
  z-index: 55;
  border: 1px solid white;
  border-radius: 15px;
}
.socialmedia::before {
  content: "";
  position: absolute;
  bottom: 101%;
  left: 50%;
  translate: -50% 0;
  width: 2px;
  height: calc(var(--socialTop) + 30px);
  border-radius: 15px;
  z-index: -4;
  background-color: var(--mainColor);
}
.socialItems i {
  transition: color 500ms !important;
  cursor: pointer !important;
}
.socialItems i:hover {
  color: var(--mainColor) !important;
}
.followMe {
  cursor: grabbing !important;
  position: absolute;
  bottom: -15px;
  left: 50%;
  translate: -50% 0;
  rotate: 90deg;
  width: 60px;
  padding: 3px;
  font-size: 11px;
  background-color: var(--mainColor);
  user-select: none !important;
}

.css-ptiqhd-MuiSvgIcon-root,
.css-1k33q06 {
  color: rgb(102, 60, 0);
}

.ownAnimate{
  animation: shake 0.04s ease-in-out forwards alternate;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(6px);
  }
  30% {
    transform: translateY(-5px);
  }
  45% {
    transform: translateY(4px);
  }
  60% {
    transform: translateY(-3px);
  }
  75% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
  }
}